import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, fontSizes, spacing}) => ({
  container: {
    margin: spacing(3, 0),
    padding: spacing(0, 20),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    [breakpoints.down('sm')]: {
      padding: 0,
    },
    '& h1': {
      fontFamily: 'CamptonBook',
      fontSize: fontSizes.largeDescription,
      margin: 0,
    },
    '& p': {
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
      fontWeight: 300,
      color: palette.text.primary,
      whiteSpace: 'pre-line',
      textAlign: 'justify',
      [breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
}))

export default useStyles
