import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, spacing, breakpoints, shadow}) => ({
  row: {
    marginTop: spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  column: {
    width: '30%',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    margin: spacing(6, 0),
    padding: spacing(0, 20),
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    fontSize: fontSizes.largeDescription,
    fontFamily: 'CamptonBook',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  descriptionContainer: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  key: {
    margin: 0,
    padding: spacing(1, 2),
    fontFamily: 'CamptonLight',
    fontSize: fontSizes.description,
    fontWeight: 300,
  },
  number: {
    padding: spacing(2),
    fontWeight: 500,
    fontFamily: 'CamptonMedium',
    fontSize: fontSizes.title,
  },
  paper: {
    height: '100%',
    width: '100%',
    borderRadius: 20,
    transform: 'translateZ(0)',
    boxShadow: shadow.keys,
    fontWeight: 300,
    [breakpoints.down('sm')]: {
      padding: spacing(2, 0),
      marginTop: spacing(2.5),
    },
  },
}))

export default useStyles
