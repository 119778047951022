import * as R from 'ramda'
import {Button} from '@mui/material'
import {navigate} from 'gatsby'
import classNames from 'classnames'
import React from 'react'

import {keyPlayHeaderPropTypes} from 'helpers/propTypes'
import BackButton from 'components/UI/BackButton'
import Media from 'components/UI/Media'
import Title from 'components/UI/Title'

import useStyles from './styles'

const KeyPlayHeader = ({
  title,
  image,
  buttonLabel,
  industries,
  departments,
  partners,
  services,
  tags,
  allPartnerNames,
  kpi1Number,
  kpi1Description,
  kpi2Number,
  kpi2Description,
  kpi3Number,
  kpi3Description,
  isPreview,
}) => {
  const classes = useStyles()

  const heroTitle = R.pathOr('', ['title'], title)
  const gatsbyImageData = R.pathOr('', ['gatsbyImageData'], image)
  const kpiOneNumber = R.pathOr('', ['kpi1Number'], kpi1Number)
  const kpiOneDescription = R.pathOr('', ['kpi1Description'], kpi1Description)
  const kpiTwoNumber = R.pathOr('', ['kpi2Number'], kpi2Number)
  const kpiTwoDescription = R.pathOr('', ['kpi2Description'], kpi2Description)
  const kpiThreeNumber = R.pathOr('', ['kpi3Number'], kpi3Number)
  const kpiThreeDescription = R.pathOr(
    ' ',
    ['kpi3Description'],
    kpi3Description,
  )

  const backLink =
    process.env.GATSBY_WEBSITE === 'keyrus-nearshoring'
      ? '../../testimonials/'
      : '../../playbook/'

  return (
    <div className={classes.container}>
      <div className={classes.heroContainer}>
        <div className={classes.heroContent}>
          <BackButton backLink={backLink} backLabel={buttonLabel} />
          <div className={classes.titleHero}>
            <Title variant="h1" type="mainTitle">
              {heroTitle}
            </Title>
            <div className={classes.tagsContainer}>
              {departments &&
                R.map(department => {
                  const departmentName = R.pathOr(
                    ' ',
                    ['name', 'name'],
                    department,
                  )

                  return (
                    <Button
                      className={classNames(
                        classes.departmentTags,
                        classes.tag,
                      )}
                      size="small"
                      onClick={() =>
                        navigate(`../../playbook#/department-${departmentName}`)
                      }
                    >
                      #{departmentName}
                    </Button>
                  )
                }, departments)}
              {services &&
                R.map(service => {
                  const serviceName = R.pathOr(' ', ['name'], service)

                  return (
                    <Button
                      className={classNames(
                        classes.departmentTags,
                        classes.tag,
                      )}
                      size="small"
                      onClick={() =>
                        navigate(`../../playbook#/department-${serviceName}`)
                      }
                    >
                      #{serviceName}
                    </Button>
                  )
                }, services)}
              {industries &&
                R.map(industrie => {
                  const industryName = R.pathOr(
                    ' ',
                    [isPreview ? 'fields' : 'name', 'name'],
                    industrie,
                  )

                  return (
                    <Button
                      className={classNames(classes.industrieTags, classes.tag)}
                      size="small"
                      onClick={() =>
                        navigate(`../../playbook#/industry-${industryName}`)
                      }
                    >
                      #{industryName}
                    </Button>
                  )
                }, industries)}
              {partners &&
                R.map(partner => {
                  const partnerName = R.pathOr(
                    ' ',
                    isPreview ? ['fields', 'name'] : ['name'],
                    partner,
                  )
                  const shouldNavigate =
                    allPartnerNames && allPartnerNames.includes(partnerName)

                  return (
                    <Button
                      className={classNames(classes.partnerTags, classes.tag)}
                      size="small"
                      onClick={() =>
                        shouldNavigate
                          ? navigate(`../../playbook#/partner-${partnerName}`)
                          : null
                      }
                    >
                      #{partnerName}
                    </Button>
                  )
                }, partners)}
              {tags &&
                R.map(tag => {
                  const tagName = R.pathOr(
                    ' ',
                    [isPreview ? 'fields' : 'name', 'name'],
                    tag,
                  )

                  return (
                    <Button
                      className={classNames(classes.tagTags, classes.tag)}
                      size="small"
                      onClick={() => navigate(`../../playbook#/tag-${tagName}`)}
                    >
                      #{tagName}
                    </Button>
                  )
                }, tags)}
            </div>
          </div>
        </div>
        <Media data={gatsbyImageData} className={classes.heroImage} />
      </div>
      {!R.isEmpty(kpiOneNumber) && (
        <div className={classes.box}>
          <div className={classes.keyNumbers}>
            <h3>{kpiOneNumber}</h3>
            <p>{kpiOneDescription}</p>
          </div>
          {kpi2Number && (
            <div className={classes.keyNumbers}>
              <h3>{kpiTwoNumber}</h3>
              <p>{kpiTwoDescription}</p>
            </div>
          )}
          {kpi3Number && (
            <div className={classes.keyNumbers}>
              <h3>{kpiThreeNumber}</h3>
              <p>{kpiThreeDescription}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

KeyPlayHeader.propTypes = keyPlayHeaderPropTypes

KeyPlayHeader.defaultProps = {}

export default KeyPlayHeader
