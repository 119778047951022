import React from 'react'

import {keyPlaySectionPropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const KeyPlaySection = ({title, content}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.container}>
        <Title variant="h2" type="mediumTitle" fontFamily="book">
          {title}
        </Title>
        <p>{content}</p>
      </div>
    </Section>
  )
}

KeyPlaySection.propTypes = keyPlaySectionPropTypes

KeyPlaySection.defaultProps = {}

export default KeyPlaySection
