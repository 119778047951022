import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({palette, fontSizes, spacing, breakpoints, layout}) => ({
    container: {
      padding: spacing(3, 20),
      marginTop: spacing(2),
      [breakpoints.down('sm')]: {
        padding: 0,
      },
      [breakpoints.up('sm')]: {
        minHeight: layout.keyplayPartners.container.minHeight,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        alignItems: 'start',
      },
    },
    title: {
      fontSize: fontSizes.largeDescription,
      fontFamily: 'CamptonBook',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      marginBottom: 0,
    },
    row: {
      display: 'flex',
      marginTop: spacing(3),
      [breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
      },
      '& > div:first-child': {
        flex: '15%',
      },
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: spacing(1.5),
      },
    },
    column: {
      flex: '50%',
      margin: 'auto',
      [breakpoints.down('sm')]: {
        margin: 0,
      },
    },

    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      height: layout.keyplayPartners.image.height,
      width: layout.keyplayPartners.image.width,
      position: 'relative',
      justifyContent: 'center',
      margin: spacing(1.2),
      '& img': {
        maxWidth: '80%',
        maxHeight: '80%',
      },
    },

    description: {
      marginTop: spacing(1.5),
    },
    text: {
      marginTop: spacing(1),
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
      color: palette.text.primary,
      textAlign: 'justify',
    },

    rightArrow: {
      marginLeft: spacing(4),
      '& > *': {
        transform: 'rotate(180deg)',
      },
    },
    slideNavigation: {
      opacity: 0.5,
      width: 275,
      '& > *': {
        height: 75,
        width: 75,
        border: '1px solid rgba(0, 0, 0, 0.5)',
      },
    },

    numbersGrid: {
      marginBottom: spacing(3),
      display: 'flex',
      '&>div': {
        flexGrow: 1,
        width: '33%',
        marginRight: spacing(3),
      },
      '&>div:last-child': {
        right: 0,
        margin: 0,
      },
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: spacing(1.5),
        '&>div': {
          margin: '0',
          width: '100%',
        },
      },
    },
    numbers: {
      marginBottom: 0,
      fontSize: fontSizes.subTitle,
      fontFamily: 'CamptonMedium',
      fontWeight: 500,
      color: palette.text.primary,
    },
  }),
)

export default useStyles
