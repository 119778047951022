import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulHomepagePropTypes,
  allContentfulKeyPlayPropTypes,
  allContentfulPlaybookPropTypes,
  locationPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'

import {formRedirection, socialShare} from 'helpers/utils'
import KeyPlayHeader from 'components/Keyplay/Header'
import KeyPlaySection from 'components/Keyplay/Section'
import KeyPlayTestimonial from 'components/Keyplay/Testimonial'
import Keys from 'components/Keyplay/Keys'
import Partners from 'components/Keyplay/Partners'
import Related from 'components/UI/Related'
import SEO from 'components/seo'
import SocialShare from 'components/UI/SocialShare'
import SwitchForm from 'components/UI/SwitchForm'
import useIsBrowser from 'hooks/useIsBrowser'

const KeyPlay = ({pageContext, data, location}) => {
  const {prefix} = pageContext
  const {countryCode, navLocale, salesforce} = R.pathOr(
    null,
    ['contentfulCountry'],
    data,
  )
  const allPartnerNames = R.map(
    partner => partner.name,
    R.pathOr(null, ['contentfulCountry', 'partners'], data),
  )
  const countryData = R.path(['contentfulCountry'], data)

  const {playbook} = R.pathOr(
    ' ',
    ['allContentfulSocialMediaShare', 'edges', 0, 'node'],
    data,
  )

  const socialMediaIcons = R.pathOr(
    ' ',
    ['allContentfulSocialMediaShare', 'edges'],
    data,
  )

  const {
    image,
    background,
    approach,
    benefits,
    challenge,
    keyResult1,
    keyResult2,
    keyResult3,
    kpi1Number,
    kpi1Description,
    kpi2Number,
    kpi2Description,
    kpi3Number,
    kpi3Description,
    partners,
    industries,
    departments,
    services2,
    tags,
    clientTestimonial,
    slug,
  } = R.pathOr(' ', ['allContentfulKeyPlay', 'edges', 0, 'node'], data)

  const headerTitle = R.pathOr(
    ' ',
    ['allContentfulKeyPlay', 'edges', 0, 'node', 'title'],
    data,
  )

  const metaTitle = R.pathOr(
    ' ',
    ['allContentfulKeyPlay', 'edges', 0, 'node', 'metaTitle'],
    data,
  )

  const metaDescription = R.pathOr(
    ' ',
    ['allContentfulKeyPlay', 'edges', 0, 'node', 'metaDescription'],
    data,
  )

  const testimonial = R.pathOr(
    ' ',
    ['allContentfulKeyPlay', 'edges', 0, 'node', 'clientTestimonial'],
    data,
  )

  const {
    keyPlayBackgroundLabel,
    keyPlayApproachLabel,
    keyPlayBackButtonLabel,
    keyPlayBenefitsLabel,
    keyPlayChallengeLabel,
    keyPlayKeyResultsLabel,
    keyPlayPartnersLabel,
    keyPlayCustomerTestimonyLabel,
    relatedEntries: {relatedKeyPlaysTitle},
  } = R.pathOr(' ', ['allContentfulPlaybook', 'nodes', 0], data)

  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const relatedKeyplays = R.pathOr(null, ['relatedKeyplays', 'edges'], data)

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const formRedirect = formRedirection(siteURL, countryCode, navLocale, 'lead')

  const pageMetaData = {
    article: true,
    image: image && R.pathOr(' ', ['gatsbyImageData'], image),
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <KeyPlayHeader
        buttonLabel={keyPlayBackButtonLabel}
        title={headerTitle}
        image={image}
        industries={industries}
        departments={departments}
        partners={partners}
        allPartnerNames={allPartnerNames}
        services={process.env.GATSBY_WEBSITE === 'kls' && services2}
        tags={tags}
        kpi1Number={kpi1Number}
        kpi1Description={kpi1Description}
        kpi2Number={kpi2Number}
        kpi2Description={kpi2Description}
        kpi3Number={kpi3Number}
        kpi3Description={kpi3Description}
      />
      <KeyPlaySection
        title={keyPlayBackgroundLabel}
        content={R.pathOr(' ', ['background'], background)}
      />
      <KeyPlaySection
        title={keyPlayChallengeLabel}
        content={R.pathOr(' ', ['challenge'], challenge)}
      />
      <KeyPlaySection
        title={keyPlayApproachLabel}
        content={R.pathOr(' ', ['approach'], approach)}
      />
      <Keys
        title={keyPlayKeyResultsLabel}
        key1={keyResult1}
        key2={keyResult2}
        key3={keyResult3}
      />
      <KeyPlaySection
        title={keyPlayBenefitsLabel}
        content={R.pathOr(' ', ['benefits'], benefits)}
      />
      {clientTestimonial && (
        <KeyPlayTestimonial
          title={keyPlayCustomerTestimonyLabel}
          data={testimonial}
        />
      )}
      {partners && (
        <Partners title={keyPlayPartnersLabel} partners={partners} />
      )}

      <SocialShare
        location={useIsBrowser && location}
        siteURL={siteURL}
        label={R.pathOr(' ', ['keyPlayShareLabel'], playbook[0])}
        socialMediaLinks={socialMediaIcons}
        title={R.pathOr(' ', ['title'], headerTitle)}
        redirect={socialShare(siteURL, countryCode, navLocale, prefix, slug)}
      />
      <SwitchForm
        data={formData}
        location={location}
        locale={navLocale}
        redirection={formRedirect}
        redirect={formRedirect}
        websiteSource={R.pathOr('', ['title'], headerTitle)}
        hubSpotCountry={salesforce}
        isArticle
      />

      {relatedKeyplays && (
        <Related
          prefix="../"
          cards={relatedKeyplays}
          title={relatedKeyPlaysTitle}
        />
      )}
    </>
  )
}

KeyPlay.propTypes = {
  data: shape({
    allContentfulKeyPlay: allContentfulKeyPlayPropTypes,
    allContentfulPlaybook: allContentfulPlaybookPropTypes,
    allContentfulHomepage: allContentfulHomepagePropTypes,
  }),
  location: locationPropTypes,
  pageContext: pageContextPropTypes,
}

KeyPlay.defaultProps = {
  data: {},
  location: {},
  pageContext: {},
}

export default KeyPlay

export const pageQuery = graphql`
  query templateKeyPlay(
    $entityRegEx: String
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    allContentfulKeyPlay(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {eq: $contentId}}
    ) {
      edges {
        node {
          ...keyPlayFields
        }
      }
    }
    relatedKeyplays: allContentfulKeyPlay(
      filter: {
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
        node_locale: {eq: $nodeLocale}
        contentful_id: {ne: $contentId}
      }
      limit: 10
    ) {
      edges {
        node {
          title {
            title
          }
          slug
          image {
            file {
              url
            }
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    allContentfulPlaybook(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        keyPlayApproachLabel
        keyPlayBackButtonLabel
        keyPlayBackgroundLabel
        keyPlayBenefitsLabel
        keyPlayChallengeLabel
        keyPlayCustomerTestimonyLabel
        keyPlayKeyResultsLabel
        keyPlayPartnersLabel
        keyPlayShareLabel
        relatedEntries {
          relatedKeyPlaysTitle
        }
      }
    }
    allContentfulSocialMediaShare(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          id
          playbook {
            keyPlayShareLabel
          }
          icon {
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      salesforce
      partners {
        name
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulForm(
      name: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      ...FormFields
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
