import * as R from 'ramda'
import React, {useState} from 'react'
import RoundButtonSlider from 'components/UI/RoundButtonSlider'

import {partnersPropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Partners = ({partners, title}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const [index, setIndex] = useState(0)

  const handleClickPrevious = () => {
    const newIndex = index - 1

    setIndex(newIndex < 0 ? R.length(partners) - 1 : newIndex)
  }
  const handleClickNext = () => {
    const newIndex = index + 1

    setIndex(newIndex > R.length(partners) - 1 ? 0 : newIndex)
  }

  const data = {
    image: R.pathOr(' ', ['logo', 'file', 'url'], partners[index]),
    title: R.pathOr(' ', ['name'], partners[index]),
    description: R.pathOr(
      ' ',
      ['shortDescription', 'shortDescription'],
      partners[index],
    ),
    kpi1Number: R.pathOr(' ', ['kpi1Number', 'kpi1Number'], partners[index]),
    kpi2Number: R.pathOr(' ', ['kpi2Number', 'kpi2Number'], partners[index]),
    kpi3Number: R.pathOr(' ', ['kpi3Number', 'kpi3Number'], partners[index]),
    kpi1Description: R.pathOr(
      ' ',
      ['kpi1Description', 'kpi1Description'],
      partners[index],
    ),
    kpi2Description: R.pathOr(
      ' ',
      ['kpi2Description', 'kpi2Description'],
      partners[index],
    ),
    kpi3Description: R.pathOr(
      ' ',
      ['kpi3Description', 'kpi3Description'],
      partners[index],
    ),
  }

  const renderPartners = partnerData => (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title variant="h2" type="mediumTitle" fontFamily="book">
          {title}
        </Title>
        {!isMobile && partners.length > 1 && (
          <RoundButtonSlider
            isCentered={isMobile}
            hasMarginTop
            increment={() => handleClickNext()}
            decrement={() => handleClickPrevious()}
          />
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.column}>
          <div className={classes.imageContainer}>
            <img src={partnerData.image} alt="" loading="lazy" />
          </div>
        </div>
        <div className={classes.column}>
          <Title variant="h3" type="subTitle">
            {partnerData.title}
          </Title>
          <div className={classes.description}>
            <p className={classes.text}>{partnerData.description}</p>
          </div>
        </div>
      </div>
      {partnerData.kpi1Number && (
        <div className={classes.numbersGrid}>
          <div>
            <p className={classes.numbers}>{partnerData.kpi1Number}</p>
            <p className={classes.text}>{partnerData.kpi1Description}</p>
          </div>
          <div>
            <p className={classes.numbers}>{partnerData.kpi2Number}</p>
            <p className={classes.text}>{partnerData.kpi2Description}</p>
          </div>
          <div>
            <p className={classes.numbers}>{partnerData.kpi3Number}</p>
            <p className={classes.text}>{partnerData.kpi3Description}</p>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      {renderPartners(data)}
    </Section>
  )
}

Partners.propTypes = partnersPropTypes

Partners.defaultProps = {}

export default Partners
